<template>
  <div class="about">
    <div class="banner">
      <img src="@/assets/about/banner.jpg" />
    </div>
    <div class="title">
      <div class="title-wrapper animate__animated" :class="animateShow.show1 ? 'animate__fadeInUp' : ''">
        <p class="cn">企业介绍</p>
        <p class="en">COMPANY PROFILE</p>
      </div>
    </div>
    <div class="profile">
      <div class="profile-wrapper">
        <div class="profile-img animate__animated" :class="animateShow.show1 ? 'animate__fadeInUp' : ''">
          <img src="@/assets/about/hdp.jpg" />
        </div>
        <div class="profile-info animate__animated" :class="animateShow.show1 ? 'animate__fadeInUp' : ''">
          <div class="info-content">
            <p>海多普，城市管理信息服务专家。</p>
            <p>
              浙江海多普之星智能技术有限公司是一家致力于将现代信息技术在城市管理领域的创新应用与深度融合的企业。公司创立于2010年，成长于杭州市高新技术产业开发区（滨江区）物联网街区，现有注册资金1000万元，员工200余人。
            </p>
            <p>
              公司专注于城市管理信息服务，以“专业、专注、共享、创新”的服务理念，为政府部门和合作伙伴提供智慧（数字）城管、市容环卫、垃圾分类、综合执法等行业相关的信息化解决方案。经过十余年的发展壮大，我司在湖州、金华、苏州、温州、阜阳、富阳、海宁等地设立了分公司，形成了立足浙江、辐射华东的营销网络和服务体系。
            </p>
            <p>
              海多普始终秉承“让工作更智慧，让环境更和谐”的使命，坚持“专业、创新、服务”的发展理念，遵循“服务客户、精准求实、诚信共享、创业创新”的价值观，以立体式多纬度密集型的本地化服务为立足点，把满足客户的需求作为公司生存的源泉，把提升客户价值作为公司发展的动力，脚踏实地，锐意进取，用速度满足市场，用品质迎接竞争，用信誉维系客户，倾力回报社会，努力将企业打造成智慧城市信息化建设领域内的一流企业。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="title">
      <div class="title-wrapper animate__animated" :class="animateShow.show2 ? 'animate__fadeInUp' : ''">
        <p class="cn">公司荣誉</p>
        <p class="en">COMPANY HONOR</p>
      </div>
    </div>
    <div class="honor">
      <div class="honor-wrapper animate__animated" :class="animateShow.show2 ? 'animate__fadeInUp' : ''">
        <img class="prev-btn" src="@/assets/about/prev.png" @click="handleHonor(0)">
        <div v-for="(item, i) in honorList" :key="i" :class="['honorBox', 'honorBox' + currenIndex(i + 1)]" @click="hIndex = i + 1">
          <img :src="item" />
        </div>
        <img class="next-btn" src="@/assets/about/next.png" @click="handleHonor(1)">
      </div>
    </div>
    <div class="qualifications">
      <div class="title">
        <div class="title-wrapper animate__animated" :class="animateShow.show3 ? 'animate__fadeInUp' : ''">
          <p class="cn">体系认证/软著</p>
          <p class="en">SYSTEM CERTIFICATE/SOFTWARE COPYRIGHT</p>
        </div>
      </div>
      <div class="content">
        <div class="content-wrapper animate__animated" :class="animateShow.show3 ? 'animate__fadeInUp' : ''">
          <div class="imgBox animate">
            <img src="@/assets/about/zs01.png" />
            <img src="@/assets/about/zs02.png" />
            <img src="@/assets/about/zs03.png" />
            <img src="@/assets/about/zs04.png" />
            <img src="@/assets/about/zs05.png" />
            <img src="@/assets/about/zs06.png" />
            <img src="@/assets/about/zs07.png" />
            <img src="@/assets/about/zs08.png" />
            <img src="@/assets/about/zs09.png" />
            <img src="@/assets/about/zs10.png" />
            <img src="@/assets/about/zs01.png" />
            <img src="@/assets/about/zs02.png" />
            <img src="@/assets/about/zs03.png" />
            <img src="@/assets/about/zs04.png" />
            <img src="@/assets/about/zs05.png" />
            <img src="@/assets/about/zs06.png" />
            <img src="@/assets/about/zs07.png" />
            <img src="@/assets/about/zs08.png" />
            <img src="@/assets/about/zs09.png" />
            <img src="@/assets/about/zs10.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="title">
      <div class="title-wrapper animate__animated" :class="animateShow.show4 ? 'animate__fadeInUp' : ''">
        <p class="cn">联系我们</p>
        <p class="en">CONTACT US</p>
      </div>
    </div>
    <div class="contact">
      <div class="contact-wrapper">
        <div class="left animate__animated" :class="animateShow.show4 ? 'animate__fadeInUp' : ''">
          <div id="map-wrapper"></div>
        </div>
        <div class="right animate__animated" :class="animateShow.show4 ? 'animate__fadeInUp' : ''">
          <p class="input">
            <input type="text" v-model.trim="form.name" placeholder="您的名字" />
          </p>
          <p class="input">
            <input type="text" v-model.trim="form.contact" placeholder="您的联系方式" />
          </p>
          <p class="input2">
            <textarea rows="5" v-model.trim="form.content" placeholder="留言给我们" />
          </p>
          <a :class="['submit', sStop ? 'dis' : '']" @click="submit">提交留言</a>
        </div>
      </div>
    </div>
    <!-- <div class="toast" v-if="success">
      <svg t="1640770540620" v-if="type === 0" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2314" width="36" height="36"><path d="M122.2656 565.0432c-9.9328-9.9328-11.5712-25.3952-2.7648-36.4544l20.48-25.8048c8.3968-10.5472 24.064-13.2096 35.2256-5.8368l177.3568 118.3744c9.3184 6.2464 25.4976 5.4272 34.2016-1.6384L855.3472 233.472c10.5472-8.4992 27.0336-7.7824 36.2496 1.4336l11.5712 11.5712c10.0352 10.0352 9.3184 25.9072-1.2288 36.4544L398.9504 785.1008c-15.6672 15.6672-41.3696 14.9504-57.4464-1.1264L122.2656 565.0432z" fill="#ffffff" p-id="2315"></path></svg>
      <svg t="1640770765627" v-if="type === 1" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6644" width="36" height="36"><path d="M597.333333 213.333333c0 46.933333-38.4 85.333333-85.333333 85.333334s-85.333333-38.4-85.333333-85.333334 38.4-85.333333 85.333333-85.333333 85.333333 38.4 85.333333 85.333333zM597.333333 810.666667c0 46.933333-38.4 85.333333-85.333333 85.333333s-85.333333-38.4-85.333333-85.333333v-341.333334c0-46.933333 38.4-85.333333 85.333333-85.333333s85.333333 38.4 85.333333 85.333333v341.333334z" fill="#ffffff" p-id="6645"></path></svg>
      <div class="toast_text">{{tipText}}</div>
    </div> -->
    <Toast ref="myToast"></Toast>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import request from '@/api/http'
import { sendMessage } from '@/api/urls'
import Toast from '@/components/Toast'
export default {
  name: 'About',
  metaInfo: {
    title: '智慧城管|垃圾治理|垃圾分类|智慧环卫|智慧公厕|智能AI分析-海多普-关于我们',
    meta: [
      {
        name: 'keyWords',
        content: '海多普,关于我们,智慧城管,垃圾治理,垃圾分类,智慧环卫,智慧公厕,智能AI分析'
      },
      {
        name: 'description',
        content: '海多普，城市管理信息服务专家'
      }
    ]
  },
  components: {
    Toast
  },
  data() {
    return {
      map: null,
      honorList: [
        require('@/assets/about/honor1.png'),
        require('@/assets/about/honor2.png'),
        require('@/assets/about/honor3.png'),
        require('@/assets/about/honor4.png'),
        require('@/assets/about/honor5.png'),
        require('@/assets/about/honor6.jpg')
      ],
      hIndex: 1,
      form: {
        name: '',
        contact: '',
        content: ''
      },
      success: false,
      type: 0,
      tipText: '提交成功',
      sStop: false,
      animateShow: {
        show1: true,
        show2: false,
        show3: false,
        show4: false
      }
    }
  },
  created() {
    this.initMap()
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll, true)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: 'd99f15dcd099b6087ff5cbcabac315a1'
      })
        .then((AMap) => {
          this.map = new AMap.Map('map-wrapper', {
            center: [120.215894, 30.184865],
            zoom: 18
          })
          var marker = new AMap.Marker({
            position: new AMap.LngLat(120.215894, 30.184865)
            // title: el.title,
            // icon: new AMap.Icon({
            //   image: this.icon,
            //   size: new AMap.Size(22, 32),
            //   imageSize: [22, 32],
            //   imageOffset: new AMap.Pixel(0, 0)
            // }),
            // offset: new AMap.Pixel(-11, -29)
          })
          this.map.add(marker)
          // this.map.setFitView(marker)
        })
        .catch((err) => {
          console.error('地图初始化出错:', err)
        })
    },
    currenIndex(i) {
      var c = 3
      if (i === this.hIndex) {
        return c
      }
      if ((i - this.hIndex) > 0) {
        return (c + (i - this.hIndex)) > this.honorList.length 
        ? (c + (i - this.hIndex)) - this.honorList.length 
        : (c + (i - this.hIndex))
      }
      if ((i - this.hIndex) < 0) {
        return (c + (i - this.hIndex)) < 0 
        ? (c + (i - this.hIndex)) + this.honorList.length 
        : (c + (i - this.hIndex))
      }
    },
    handleHonor(type) {
      if (type === 0) {
        if (this.hIndex === 0) {
          this.hIndex = this.honorList.length - 1
        } else {
          this.hIndex -= 1
        }
      } else {
        if (this.hIndex === this.honorList.length - 1) {
          this.hIndex = 0
        } else {
          this.hIndex += 1
        }
      }
    },
    async submit() {
      if (this.sStop) return
      this.sStop = true
      if (this.form.name === '') {
        this.showTip(1, '请输入您的名字')
        this.sStop = false
        return
      }
      if (this.form.name === '') {
        this.showTip(1, '请输入您的联系方式')
        this.sStop = false
        return
      }
      if (this.form.name === '') {
        this.showTip(1, '请输入您的留言')
        this.sStop = false
        return
      }
      let res = await request.post(sendMessage, this.form)
      this.sStop = false
      if (!res) return
      if (res.code === 200) {
        this.showTip(0, '留言成功')
        Object.assign(this.$data.form, this.$options.data().form)
      } else {
        this.showTip(0, res.msg)
      }
    },
    showTip(type, msg) {
      this.$refs.myToast.show(type, msg)
    },
    handleScroll(e) {
      var scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop
      if (scrollTop >= 500) {
        this.animateShow.show2 = true
      }
      if (scrollTop >= 1000) {
        this.animateShow.show3 = true
      }
      if (scrollTop >= 1900) {
        this.animateShow.show4 = true
      }
      console.log(scrollTop)
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
  width: 100%;
  .banner {
    width: 100%;
    overflow: hidden;
    margin-bottom: 60px;
    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
  .animate__animated {
    opacity: 0;
  }
  .title {
    height: 60px;
    margin-bottom: 50px;
    .title-wrapper {
      width: 1200px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .cn {
        font-size: 30px;
      }
      .en {
        font-size: 16px;
        color: #99acbc;
        font-weight: 600;
      }
    }
  }
  .profile {
    width: 100%;
    margin-bottom: 60px;
    .profile-wrapper {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      .profile-img {
        width: 334px;
        height: 404px;
        overflow: hidden;
      }
      .profile-info {
        width: calc(100% - 334px);
        height: 100%;
        padding-left: 66px;
        // padding-top: 20px;
        position: relative;
        .info-content {
          p {
            text-indent: 2em;
            font-size: 18px;
            line-height: 28px;
            &:nth-child(1) {
              font-weight: bold;
              line-height: normal;
              color: #194898;
              text-indent: 0em;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .honor {
    width: 100%;
    height: 476px;
    // margin-bottom: 50px;
    background-color: #f5f5fb;
    overflow: hidden;
    .honor-wrapper {
      width: 1200px;
      height: 354px;
      margin: 60px auto;
      position: relative;
      .honorBox {
        position: absolute;
        // display: block;
        left: 50%;
        cursor: pointer;
        z-index: 6;
        transform: translateX(20%) scale(0.64);
        transition: all .5s;
        &.honorBox1 {
          z-index: 7;
          transform: translateX(-120%) scale(0.64);
        }
        &.honorBox2 {
          z-index: 8;
          transform: translateX(-93%) scale(0.8);
        }
        &.honorBox3 {
          z-index: 9;
          transform: translateX(-50%);
        }
        &.honorBox4 {
          z-index: 8;
          transform: translateX(-7%) scale(0.8);
        }
        &.honorBox5 {
          z-index: 7;
          transform: translateX(20%) scale(0.64);
        }
        img {
          width: 500px;
          display: block;
        }
      }
      .prev-btn, .next-btn {
        width: 58px;
        height: 58px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .prev-btn {
        left: 0;
      }
      .next-btn {
        right: 0;
      }
      // .swiper-slide {
      //   transform: scale(0.7);
      //   z-index: 98;
      // }
      // .swiper-slide-active,
      // .swiper-slide-duplicate-active {
      //   transform: scale(1);
      //   z-index: 100;
      // }
      // .swiper-slide.swiper-slide-prev {
      //   transform: scale(0.8);
      //   z-index: 99;
      //   margin-right: -100px;
      // }
      // .swiper-slide.swiper-slide-next {
      //   transform: scale(0.8);
      //   z-index: 99;
      //   margin-left: -100px;
      // }
      // .swiper-slide-duplicate {
      //   z-index: 97;
      // }
    }
  }
  .qualifications {
    width: 100%;
    height: 796px;
    background-image: url('~@/assets/about/sys_bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 43px;
    margin-bottom: 60px;
    .title {
      .cn {
        color: #ffffff;
      }
    }
    .content {
      width: 100%;
      height: 529px;
      margin-top: 60px;
      overflow: hidden;
      .content-wrapper {
        height: 100%;
        width: auto;
        .imgBox {
          display: flex;
          flex-wrap: nowrap;
          transform: translate3d(16px, 0, 0);
          img {
            &:not(:last-child) {
              margin-right: 16px;
            }
          }
          &.animate {
            animation-duration: 26s;
            animation-timing-function: linear;
            animation-name: rollToLeft;
            animation-iteration-count: infinite;
            &:hover {
              -webkit-animation-play-state: paused;
              animation-play-state: paused;
            }
          }
          @keyframes rollToLeft {
            0% {
              transform: translate3d(16px, 0, 0);
            }
            100% {
              transform: translate3d(-3824px, 0, 0);
            }
          }
        }
      }
    }
  }
  .contact {
    width: 100%;
    margin-bottom: 60px;
    .contact-wrapper {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .left,
      .right {
        height: 530px;
        background: #ffffff;
        box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
      }
      .left {
        width: 510px;
        #map-wrapper {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        width: 672px;
        padding: 125px 100px 0 100px;
        .input {
          height: 36px;
          border-bottom: 1px solid #d9d9d9;
          margin-bottom: 22px;
          input {
            width: 100%;
          }
        }
        .input2 {
          padding-bottom: 24px;
          border-bottom: 1px solid #d9d9d9;
          textarea {
            width: 100%;
            resize: none;
            font-size: 16px;
            padding: 0;
            &::-webkit-scrollbar {
              width: 8px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba($color: #2d2d2d, $alpha: 0.15);
              border-radius: 8px;
            }
          }
        }
        .submit {
          width: 185px;
          height: 53px;
          background: #194898;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          color: #ffffff;
          margin-top: 30px;
          cursor: pointer;
          &.dis {
            background-color: #d9d9d9;
          }
        }
      }
    }
  }
  .toast {
    position: fixed;
    top: 50%;
    left: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    box-sizing: content-box;
    width: 88px;
    max-width: 70%;
    min-height: 88px;
    padding: 16px;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
    text-align: center;
    word-break: break-all;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    z-index: 100;
    .toast_text {
      margin-top: 8px;
    }
  }
}
</style>
